
import { Component, Vue, Prop } from "vue-property-decorator";
import { formatCurrency, formatTooltip } from "@/utils/formatters";
import { getActivityCommission } from "@/utils/model-helpers";

@Component
export default class DossierCommission extends Vue {
    @Prop() activity!: Activity;

    @Prop({ default: true }) showPercent!: boolean;

    formatCurrency = formatCurrency;

    formatTooltip = formatTooltip;

    get commission() {
        return getActivityCommission(this.activity);
    }

    get commissionDescription() {
        if (!this.commission.calculation) {
            return;
        }

        let description = "";

        this.commission.calculation.forEach((calc) => {
            if (!calc.commission) {
                description += `<span  class="text-white opacity-90 font-medium">${calc.note}</span> </br>`;
            } else {
                description += `${calc.note} </br>`;
            }
        });

        return description;
    }

    get commissionValue() {
        if (!this.commission.commission) {
            return false;
        }

        if (this.activity.fixed_commission) {
            return formatCurrency(this.commission.commission);
        }

        // Inclusief BTW
        if (this.activity.commission_vat_inclusive) {
            return `${this.showPercent ? this.activity.relative_commission + "% - " : ""}${formatCurrency(this.commission.commission)}`;
        }

        // Exclusief BTW
        return `${this.showPercent ? this.activity.relative_commission + "% - " : ""}${formatCurrency(this.commission.commission)}`;
    }
}
